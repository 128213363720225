import { render, staticRenderFns } from "./default.vue?vue&type=template&id=ee1e4fc8&"
import script from "./default.vue?vue&type=script&lang=js&"
export * from "./default.vue?vue&type=script&lang=js&"
import style0 from "./default.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Header: require('/devcloud/slavespace/slave1-new/workspace/j_52qd1tlN/src/components/header/Header.vue').default,Bottom: require('/devcloud/slavespace/slave1-new/workspace/j_52qd1tlN/src/components/footer/Bottom.vue').default})
